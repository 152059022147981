@import '../../styles/variables';
@import '../../styles/mixins';
.faq {
  .element {
    position: absolute;
    right: 0;
    top: 5%;
    animation: float 4s infinite linear;
  }
  .container {
    position: relative;
    z-index: 9;
    .faq-card-wrapper {
      padding: 2px;
      margin-top: 2em;
      border-radius: 24px;
      .faq-card {
        background-color: $dark;
        padding: 2em 2em;
        border-radius: 24px;
        span {
          opacity: 0.3;
          margin-bottom: 1em;
        }
        .title {
          @include justify-between;
          h4 {
            text-transform: uppercase;
          }
        }
        p {
          margin: 1em 0 0 0;
          color: #bdbdbd;
        }
        li {
          font-family: 'Space Mono', monospace;
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .faq {
    h4 {
      font-size: 1em;
      text-align: left;
    }
  }
}
