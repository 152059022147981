@import '../../styles/variables';
.footer {
  background-color: #161616;
  color: $white;
  padding: 2em 0 1em 0;
  margin: 0;
  h1 {
    margin-bottom: 1em;
    font-size: 2em;
    font-weight: 500;
    img {
      max-width: 100px;
    }
  }
  p {
    text-align: center;
    margin-top: 2em;
    color: #98a2b3;
  }
  ul {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    &.nav-list {
      margin-bottom: 50px;
    }
    &.social-links {
      li {
        margin-left: 1em;
      }
    }
    li {
      margin: 0;
      color: $white;
      a,
      a:hover {
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
@media (max-width: $md) {
  .footer {
    text-align: center;
    ul {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
