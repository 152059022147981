@import '../styles/variables';
@import '../styles/mixins';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Outfit', sans-serif;
}

body,
html {
  background-color: $dark;
}

section {
  position: relative;
  overflow: hidden;
  background-color: $dark;
  color: $white;
  padding: 5em 0;
  .title.main {
    margin-bottom: 3em;
  }
}

a,
a:hover {
  color: inherit;
}

.navbar-toggler {
  box-shadow: none !important;
}

button {
  outline: 0;
  padding: 0.6em 1.3em;
  background-color: transparent;
  color: $white;
  border: 1px solid transparent;
  background-color: $blue;
  border-radius: 8px;
  @include justify-center;
}

.shadow {
  box-shadow: none !important;
}
