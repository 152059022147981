@import '../../styles/variables';
@import '../../styles/mixins';
.hero {
  border-bottom: 1px solid $blue;
  padding: 0;
  overflow: visible;

  .background {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .container {
    position: relative;
    z-index: 9;
    .content-wrapper {
      min-height: 100vh;
      .content {
        padding: 3em 0;
        @include justify-between;
        h1 {
          font-size: 2em;
          font-weight: 500;
        }
      }
      .slogan {
        margin-top: 5em;
        color: $white;
        h1 {
          font-weight: 700;
          font-size: 3em;
          margin-bottom: 0.7em;
        }
        .hero-coin {
          width: 80%;
          animation: float 5s infinite linear;
          max-width: 400px;
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .hero {
    overflow: hidden;
    border-bottom: 0;
    padding-bottom: 100px;
    .background {
      bottom: -200px;
      min-width: 1400px;
    }
    .container {
      .content-wrapper {
        min-height: auto;
        .content {
          padding: 2em 1em 2em 0;
          button {
            font-size: 0.8em;
            padding: 0.4em 0.8em;
          }
          img {
            max-width: 55px;
          }
          h1 {
            font-size: 1em;
          }
          .btn-group {
            flex-direction: column;
            button {
              margin: 1em 0;
            }
          }
        }
        .slogan {
          .hero-coin {
            margin-top: 2em;
          }
          h1 {
            font-size: 2em;
          }
        }
      }
    }
  }
}
