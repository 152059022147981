@import '../../styles/variables';
@import '../../styles/mixins';
.working {
  padding-top: 300px;
  .working-card {
    // height: 100%;
    padding: 2em;
    background: #151419;
    border: 1px solid #08b9fc;
    border-radius: 24px;
    span {
      opacity: 0.3;
    }
    h2 {
      font-size: 1.5em;
    }
    .shadow {
      -webkit-text-stroke: 1px rgba(8, 185, 252, 0.4);
    }
    .title {
      margin-bottom: 1.5em;
    }
  }
}
@media (max-width: $sm) {
  .working {
    padding-top: 100px;
  }
}
