@import '../../styles/variables';
@import '../../styles/mixins';
.navbar {
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid $white;
  .social-links {
    img {
      margin-right: 1.5em;
      max-width: 24px;
    }
  }
  .nav-item {
    .nav-link {
      color: $white;
      cursor: pointer;
    }
  }
}

@media (max-width: $xxl) {
  .navbar {
    .nav-item {
      margin-top: 1em;
      @include justify-center;
    }
  }
}
