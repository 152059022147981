@import '../../styles/variables';
@import '../../styles/mixins';
.distribution {
  max-width: 90%;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  background: #151419;
  border: 1px solid #08b9fc;
  border-radius: 24px;
  padding: 2em;
  text-align: center;
  color: $white;
  h3 {
    color: $blue;
    margin-bottom: 1em;
  }
}

@media (max-width: $sm) {
  .distribution {
    margin: 3em auto;
    position: relative;
    transform: none;
  }
}
