@mixin justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin align-center {
  display: flex;
  align-items: center;
}
@mixin justify-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
