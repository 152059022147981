@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');

// font-family: 'Outfit', sans-serif;

@import './mixins';
@import './variables';
@import './resets';

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  position: relative;
  text-align: center;
  .heading {
    text-transform: uppercase;
    font-size: 3em;
    position: relative;
    z-index: 3;
  }
  .shadow {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5em;
    -webkit-text-stroke: 1px $blue;
    color: $dark;
    letter-spacing: 0.1em;
    font-weight: 700;
    font-family: sans-serif;
  }
}

@media (max-width: $sm) {
  .title {
    .heading {
      font-size: 2em;
    }
    .shadow {
      font-size: 2em;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(-7%);
  }
  50% {
    transform: translateY(7%);
  }
  100% {
    transform: translateY(-7%);
  }
}
