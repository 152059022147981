@import '../../styles/variables';
@import '../../styles/mixins';
.roadmap {
  .element {
    position: absolute;
    bottom: 10%;
    left: 0;
    animation: float 5s infinite linear;
  }
  .title.main {
    margin-bottom: 200px;
    .shadow {
      font-size: 3em;
    }
  }
  .container {
    .roadmap-container {
      position: relative;
      z-index: 9;
      .bar {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 5px;
        width: 800px;
        background-color: $white;
        transform: translate(-50%, -50%) rotate(90deg);
        .progress {
          width: 15%;
          height: 100%;
          background-color: $blue;
        }
      }
      .roadmap-card-container {
        margin-bottom: 100px;
        position: relative;
        &.even {
          margin-top: 100px;
        }
        .shadow {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(25%, -70%);
          font-size: 4em;
          -webkit-text-stroke: 1px $blue;
          color: $dark;
          letter-spacing: 0.1em;
          font-weight: 700;
          font-family: sans-serif;
          opacity: 0.5;
        }
        .roadmap-card {
          position: relative;
          z-index: 9;
          max-width: 500px;
          margin: auto;
          padding: 2em;
          background: #151419;
          border: 1px solid #08b9fc;
          border-radius: 24px;
          span {
            opacity: 0.3;
          }
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            margin-top: 2em;
          }
        }
      }
    }
  }
}
@media (max-width: $md) {
  .roadmap {
    .container {
      .roadmap-container {
        .bar {
          opacity: 0;
        }
      }
    }
  }
}
@media (max-width: $sm) {
  .roadmap {
    .title.main {
      margin-bottom: 3em;
      .shadow {
        font-size: 2em;
      }
    }
    .container {
      .roadmap-container {
        .bar {
          opacity: 0;
        }
        .roadmap-card-container {
          margin-bottom: 1em;
          &.even {
            margin-top: 0;
          }
          .shadow {
            font-size: 1em;
            opacity: 0;
          }
        }
      }
    }
  }
}
@media (max-width: $xs) {
  .roadmap {
    .title.main {
      .shadow {
        width: 100%;
        font-size: 2em;
      }
    }
  }
}
